import React, {
    useEffect,
    useState,
    memo, useCallback,
} from 'react';

import dynamic from 'next/dynamic';
import LazyComponent from '../components/LazyComponent';

import {
    Header,
} from 'reaxl-header';

import {
    headerBadge,
    header,
} from 'axl-config';

import { sendClick, sendImpressions } from 'reaxl-analytics';
import { ErrorBoundary } from 'reaxl-molecules';
import { addBaseUrlToNavigation } from 'atc-js';
import { redirectExternal } from '../utilities';
import { useSelector } from 'react-redux';
import {
    dealsDuck,
    paymentsDuck,
    userDuck,
    authDuck,
    recentSearchDuck,
    savedInventoryDuck,
    birfDuck,
} from '../ducks';

import { getNavigationOverrides } from '../reference';

const AuthenticationHeader = dynamic(() => import('reaxl-organisms/es/AuthenticationHeader'), { ssr: false });

function getHeaderBadgeNavItems() {
    const links = {};
    Object.keys(headerBadge).forEach((itemKey) => {
        const link = headerBadge[itemKey];
        links[itemKey] = {
            ...link,
        };
    });
    return links;
}

const ATCHeaderContainer = memo(() => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);

    const pageInstanceId = useSelector(birfDuck.selectors.getPgInst);
    const handleQuickSaveItemClick = (event, listing) => {
        redirectExternal('/cars-for-sale/vehicle/' + listing.id);
    };

    const handleQuickSaveItemToggle = (event, inventory = {}, wasSaved = false) => {
        sendClick('inventorySave', event, { inventoryId: inventory.id, wasSaved });
    };

    const handleSaveInventoryUpdate = (data = {}) => {
        const {
            listing,
            alertByText,
            alertByEmail,
        } = data;

        if (alertByText) {
            sendClick('carAlertsClick', undefined, { eventResultText: 'checkbox::alert::text-car-vin', vehicle: listing });
        }
        if (alertByEmail) {
            sendClick('carAlertsClick', undefined, { eventResultText: 'checkbox::alert::email-car-vin', vehicle: listing });
        }
    };

    const handleClick = (event, data) => {
        sendClick('headerGeneralEventHandler', event, { ...data });
    };

    const onSponsorContentReady = (e, data) => {
        sendImpressions({
            name: 'headerGeneralEventHandler',
            data: { ...data, linkContext: 'PartnersImpression' },
        });
    };

    const headerBadgeNavItems = getHeaderBadgeNavItems();

    const renderHeaderContent = useCallback(() => (
        <LazyComponent renderOnScroll={false}>
            <ErrorBoundary>
                <AuthenticationHeader
                    pageRenderId={pageInstanceId}
                    userDuck={userDuck}
                    recentSearchDuck={recentSearchDuck}
                    savedInventoryDuck={savedInventoryDuck}
                    paymentsDuck={paymentsDuck}
                    dealsDuck={dealsDuck}
                    navItems={headerBadgeNavItems}
                    onQuickSaveItemClick={handleQuickSaveItemClick}
                    onQuickSaveItemToggle={handleQuickSaveItemToggle}
                    recentSearchCount
                    onSaveInventoryUpdate={handleSaveInventoryUpdate}
                    onClick={handleClick}
                    isLoggedIn={isLoggedIn}
                    showPsxNotificationsCount
                />
            </ErrorBoundary>
        </LazyComponent>
    ), [userDuck, paymentsDuck, headerBadgeNavItems]);

    return (
        <Header
            navItems={addBaseUrlToNavigation(header.navItems, getNavigationOverrides())}
            headerContent={isClient && renderHeaderContent()}
            onClick={handleClick}
            onSponsorContentReady={onSponsorContentReady}
        />
    );
});

export default ATCHeaderContainer;
